import React from "react";

const GoogleMap = () => {
    return (
        <iframe
            title="Serenity Spa 滋足常樂"
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.869044153643!2d114.1252622749147!3d22.282949979699104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403ff9a6c4c8efb%3A0x361909fe9862fb16!2z5aCF5bC85Zyw5Z-O5Y6a5ZKM6KGXOeiZnw!5e0!3m2!1szh-TW!2shk!4v1681756432318!5m2!1szh-TW!2shk"
            aria-hidden="false"
        ></iframe>
    );
};

export default GoogleMap;
