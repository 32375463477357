import React from "react";
import { Link } from "react-router-dom";

const ContactAddress = () => {
    return (
        <div className="contact-info-content">
            <div className="info-address">
                <h2 className="title">
                    Serenity Spa <br /> <span>滋足常樂</span>
                </h2>
                <p>堅尼地城厚和街9號</p>
                {/*<Link to="/">*/}
                {/*    <span>電郵:</span> info@bellefemme.beauty*/}
                {/*</Link>*/}
            </div>
            <div className="brand-office">
                <div className="info-tem style-two">
                    <h6>電話:</h6>
                    <p>+852-55443198</p>
                </div>
                <div className="info-tem mb-0">
                    <h6>營業時間:</h6>
                    <p>星期一 - 星期日: 10:00 - 23:00</p>
                </div>
            </div>
        </div>
    );
};

export default ContactAddress;
