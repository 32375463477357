import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Footer from "../layouts/footer";

import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
// import token from "../creds/token";
import ApiCalendar from "react-google-calendar-api";
import ServiceSimpleContainer from "../containers/service-simple";
import GoogleMapContainer from "../containers/global/map";

const ServicePage = () => {
    const [dateValue, setDateValue] = React.useState(new Date());
    const setDateValue2 = (val) => {
        console.log(val);
        setDateValue(val);
        // calendarapi();
        // console.log(token.client_secret);
        if (ApiCalendar.sign) {
            const now3 = new Date();
            const now4 = new Date(now3.getDate() + 10);
            ApiCalendar.listEvents({
                timeMin: now3.toISOString(),
                timeMax: now4.toISOString(),
                showDeleted: true,
                maxResults: 10,
                orderBy: "updated",
            }).then((result) => {
                console.log(result.items);
            });
        }
    };

    const signIO = (val) => {
        if (val === "sign-in") {
            ApiCalendar.handleAuthClick()
                .then(() => {
                    console.log("sign in successful!");
                })
                .catch((e) => {
                    console.error(`sign in failed ${e}`);
                });
        } else if (val === "sign-out") {
            ApiCalendar.handleSignoutClick();
        }
    };

    return (
        <React.Fragment>
            <Layout>
                <SEO title="Serenity Spa - Service" />
                <div className="wrapper">
                    <Header />
                    {/*<div*/}
                    {/*    className="main-content site-wrapper-reveal"*/}
                    {/*    style={{ textAlign: "center" }}*/}
                    {/*>*/}
                    {/*    <img src="img/spa/services.jpg" alt="Services" />*/}
                    {/*</div>*/}
                    <div className="main-content site-wrapper-reveal">
                        <ServiceSimpleContainer />
                        <br />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ServicePage;
