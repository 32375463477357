import React from "react";
import { NavLink } from "react-router-dom";

const MainMenu = () => {
    return (
        <nav>
            <ul className="main-menu">
                <li>
                    <NavLink className="main-menu-link" exact to="/">
                        主頁
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/service"}
                    >
                        服務
                    </NavLink>
                </li>

                {/*<li>*/}
                {/*    <NavLink*/}
                {/*        className="main-menu-link"*/}
                {/*        to={process.env.PUBLIC_URL + "/blog"}*/}
                {/*    >*/}
                {/*        Blog*/}
                {/*    </NavLink>*/}
                {/*    <ul className="sub-menu">*/}
                {/*        <li>*/}
                {/*            <NavLink*/}
                {/*                className="sub-menu-link"*/}
                {/*                to={process.env.PUBLIC_URL + "/blog"}*/}
                {/*            >*/}
                {/*                Blog list*/}
                {/*            </NavLink>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <NavLink*/}
                {/*                className="sub-menu-link"*/}
                {/*                to={process.env.PUBLIC_URL + "/blog-details/1"}*/}
                {/*            >*/}
                {/*                Blog Details*/}
                {/*            </NavLink>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</li>*/}

                {/*<li>*/}
                {/*    <NavLink*/}
                {/*        className="main-menu-link"*/}
                {/*        to={process.env.PUBLIC_URL + "/about"}*/}
                {/*    >*/}
                {/*        About*/}
                {/*    </NavLink>*/}
                {/*</li>*/}
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/contact"}
                    >
                        地址
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default MainMenu;
